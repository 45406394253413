<template>
  <div id="admin">
    <div class="admin-header">
      <div><router-link to="/admin/login">Login</router-link></div>
      <!-- <div><router-link to="/admin/register">Create Account</router-link></div> -->
    </div>

    <div class="toggle-menu" @click="toggleMenu">
      <h4 class="menu-toggle">{{ displayLinks ? 'x' : '+' }}</h4>
    </div>

    <div v-if="currentUser" class="admin-header">
      <div><a href="#">{{ currentUser.email }}</a></div>
      <div><a href="#" @click="logout">Logout</a></div>
    </div>

    <div class="links-container" v-if="currentUser && displayLinks">
      <ul>
        <li>Cover Photo</li>
        <li><router-link to="/admin/preview/band-summary">Headline</router-link></li>
        <li><router-link to="/admin/preview/events">Events</router-link></li>
        <!-- <li><router-link to="/admin/preview/events">Quotes</router-link></li> -->
        <li><router-link to="/admin/preview/about">The Band</router-link></li>
        <li><router-link to="/admin/preview/gallery">Gallery</router-link></li>
        <li><router-link to="/">View Site</router-link></li>
      </ul>
    </div>
    <div>
      <!-- <h3>Preview</h3> -->
      <router-view v-if="currentUser || $route.path === '/admin/login'"></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { auth } from '../../firebase';

export default {
  name: 'Admin',
  components: {},
  data: function () {
    return {
      displayLinks: false,
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentUser',
    ]),
    logout: function logout() {
      auth.signOut().then(() => {
        console.log('logged out');
        this.setCurrentUser();
        this.$router.push('/');
      }).catch((error) => {
        console.log('error logging out...', error);
      })
    },
    toggleMenu: function () {
      this.displayLinks = !this.displayLinks;
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
    showLoginLink: function () {
      console.log(this.$route.path);
      return this.$route.path !== '/admin/login';
    }
  },
}
</script>

<style lang="scss" scoped>
$purple: #441b3b;
#admin {
  display: flex;
  margin-top: 50px;
  flex-flow: column nowrap;
  height: 100%;
  align-content: center;
}
.admin-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-between;
  height: 40px;
  background-color: rgb(51, 18, 44);
  padding: 0 10px;
}
.links-container {
  margin-bottom: 20px;
}
ul li {
  color: $purple;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 2em;
  text-align: center;
}
.admin-header a {
  color: #ccc;
}
.admin-header a:hover,
.admin-header a:active {
  color: teal;
}

li a {
  color: purple;
}
li a:hover {
  color: teal;
}
h3, h4 {
  color: #ccc;
}

.menu-toggle {
  cursor: pointer;
  border: 1px dotted purple;
  color: purple;
  display: inline-block;
  padding: 4px;
  min-width: 25px;
  border-radius: 50%;
  text-align: center;
}
</style>
